.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
    border-radius: 8px;
    background-color: #f9f9f9;
    position: relative;
    max-width: 330px;
}

.dropzone.drag-over {
    border-color: green;
}

.dropzone i {
    font-size: 24px;
    color: #007bff;
    margin-bottom: 10px;
}

.dropzone p {
    color: #888;
    font-size: 16px;
}

.dropzone .mb-0 {
    margin-bottom: 0;
}

.dropzone .error {
    color: red;
    margin-top: 10px;
}

.dropzone span {
    display: block;
    margin-top: 10px;
    color: #555;
    font-size: 14px;
    font-weight: bold;
}

.image-preview-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .image-preview-content {
    position: relative;
    // background: white;
    // padding: 10px;
    border-radius: 8px;
  }
  
  .close-button {
    position: absolute;
    top: -20px;
    right: -35px;
    background: none;
    border: none;
    font-size: 30px;
    color: white;
    cursor: pointer;
  }
  
  .file-name {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: white;
  }