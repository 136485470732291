.info_card_container {


    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    row-gap: 3.5rem;
  }


  @media (min-width: 1024px) {
    .info_card_container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  @media (min-width: 1600px) {
    .info_card_container {
          display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the gap between InfoCards as needed */
    }
  }