.page-card {
    .box {
        margin-bottom: 20px;

        .heading {
            font-size: 1.5rem;
            font-weight: bolder !important;
        }

        hr {
            border-top: var(--bs-border-width) solid;
            color: inherit;
            margin: 1rem 0;
            opacity: .25;
        }

        .details-table {
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(auto-fill, minmax(calc(33.333% - 10px), 1fr));
        }

        .details-row {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            .details-label {
                color: #1a1d1f;
                font-size: .85rem; // Adjust as needed
                font-weight: 600 !important;
                line-height: 1rem;
                margin-bottom: 5px;
            }

            .details-value {
                color: #6f767e;
                font-size: 1rem; // Adjust as needed
            }
        }
    }
}