@import '../../../assets/globalStyles/variables.scss';

.dashboard {
    background-color: $bgLight;
    min-height: 100vh;
    width : 100%;
}

.dashboard_body {
    padding: 20px 60px 0;
    min-height: 92vh;
    display: flex;
    flex-direction: column;
  
  }