@import 'variables';

.btn {
  margin-top: 0;
  border-radius: 24px !important;
  height: 48px !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 24px !important;
  font-weight: 600 !important;
  min-width: max-content;
}

.btn-primary {
  background-color: $primary;
  border: none !important;
}

.btn-sm {
  height: 40px !important;
  padding: 0 20px !important;
}

.btn-xs {
  height: 35px !important;
  padding: 2px 16px !important;
  font-size: 13px;
}

.btn-primary:hover,
.btn-primary:active {
  background-color: $primary !important;
}

.btn-primary:disabled {
  background-color: #9a9fa5 !important;
  opacity: 1 !important;
}

.btn-outline-dark {
  border: 1px solid var(--50, #c0c4c9) !important;
  color: $dark;
}

.btn-outline-primary {
  border: 1px solid $primary !important;
  color: $primary !important;

  svg {
    fill: $primary
  }
}

.btn-add {
  background-color: #145b64;
  // height: 30px;
  // width: 30px;
  border: none;
  color: white;
  border-radius: 30%;
  align-items: center;
  margin-right: 10px;
  margin-top: 27px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: $primary;

  svg {
    fill: $white;
  }
}

.btn-dark {
  background-color: $dark !important;
  color: #fff !important;
}

.btn-outline-dark:hover,
.btn-outline-dark:active {
  background-color: $light !important;
  border: 1px solid $grey0 !important;
  color: $dark !important;
}

.btn-outline-danger {
  // border: 1px solid $danger !important;
  color: $danger;

  svg {
    fill: $danger
  }
}

.btn-outline-danger:hover,
.btn-outline-danger:active {
  background-color: $danger;
  // border: 1px solid $danger;
  color: $white;

  svg {
    fill: #fff
  }
}

.btn-outline-primary {
  border: 1px solid $primary;
  color: $primary;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
}

.responsive-btn {}

.w-60 {
  width: 60% !important;
}

.w-80 {
  width: 80% !important;
}

@media screen and (max-width: 400px) {
  .responsive-btn {
    width: 100%;
  }
}

.rich-text-editor-container {
  ul {
    li {
      cursor: text !important;
      display: flex;
    }

    li::before {
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="14" height="14"><path d="M12,17c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Z"/></svg>');
      margin-right: 8px;
      margin-top: 2.5px;
      // Adjust the spacing between the icon and the conten
    }
  }

  h4 {
    font-size: 1em;
    text-align: justify;
  }
}

.no-pointer-events {
  pointer-events: none;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.MuiCircularProgress-circle {
  stroke: $primary !important;
}

.table-icons-container {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 10px;

  svg {
    cursor: pointer;
    fill: #4e5660
  }


}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-20 {
  width: 20%;
}

.value {
  color: $grey;
}

.view-container {
  label {
    margin-bottom: 0 !important;
  }
}


// .financial-data-btn {
//   display: flex;
//   align-items: center;
//   padding: 10px 20px;
//   border: none;
//   background-color: #f0f0f0;
//   border-radius: 20px;
//   cursor: pointer;
//   transition: background-color 0.3s;
// }

// .financial-data-btn:hover {
//   background-color: #e0e0e0;
// }

// .financial-data-btn .icon {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 24px;
//   height: 24px;
//   border-radius: 50%;
//   background-color: #007bff;
//   color: white;
//   font-size: 16px;
//   margin-right: 10px;
//   transition: background-color 0.3s;
// }

// .financial-data-btn .icon:hover {
//   background-color: #0056b3;
// }



.toggle-button {
  position: relative;
  display: inline-block;
  // padding: 8px 16px;
  border-radius: 20px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #000;
  /* Text color for button */
}

.toggle-button::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 0px;
  /* Start position on the right */
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 5s ease, background-color 0.3s ease;
}

.toggle-button.enabled::before {
  right: calc(100% - 22px);
  /* End position on the left */
  background-color: #145b64;
  /* Green color for enabled state */
}

.toggle-button.disabled::before {
  right: 0px;
  /* Start position on the right */
  background-color: #145b64;
  /* Red color for disabled state */
}

.toggle-button.enabled {
  background-color: #f0f0f0;
  /* Green color for enabled state */
  color: #fff;
  /* Text color for enabled state */
}

.toggle-button.disabled {
  background-color: #f0f0f0;
  /* Red color for disabled state */
}

.button-text {
  position: relative;
  z-index: 1;
  color: transparent;
}

.my-icon {
  color: white;
}

.styled-button {
  background-color: #145b64;
  color: white;
  font-size: 16px;
  border-radius: 40px;
  padding: 10px 24px;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #145b64;
  }

  &:focus {
    outline: none;
  }
}


.error-message {
  color: #be0f00;        
  margin-top: 5px;        
  font-size: 12px;        
  font-weight: 500;       
  line-height: 1.5;       
}

input:disabled {
  color: hsl(0, 0%, 60%) !important; 
}