@import "../../../assets/globalStyles/variables.scss";

// Mixins
@mixin flex-center($justify: center, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

// Navbar Container
.navbar_container {
  position: sticky;
  z-index: 1000;
  top: 0;
}

// Reset List Styles
.navbar ul,
.switching_header ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

// Anchor Styles
a {
  text-decoration: none !important;
  color: black;
  font-size: 18px;
}

// Navbar Styles
.navbar {
  background-color: $white;
  padding: 10px 60px;
  @include flex-center(space-between, center);
  gap: 40px;
  height: 72px;
  z-index: 0;
  border-bottom: 0.1px solid #EAEBF6;


  @media screen and (max-width: 1200px) {
    gap: 10px;
    padding: 10px 20px;

    img {
      height: 40px;
    }
  }

  @media screen and (max-width: 1233px) {
    .hamburger {
      display: block;
      cursor: pointer;

      .bar {
        background-color: $grey;
        width: 20px;
        height: 2px;
        margin: 4px;
        transition: 0.3s ease-in-out;
      }

      &.active .bar:nth-child(2) {
        opacity: 0;
      }

      &.active .bar:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
      }

      &.active .bar:nth-child(3) {
        transform: translateY(-7px) rotate(-45deg);
      }
    }
  }

  // img {
  //   height: 43px !important;
  // }

  .navLink {
    svg {
      height: 20px;
      width: 20px;
      font-size: 20px;
      margin-right: 5px !important;
      color: $white;
      fill: $white;
    }
  }

  .left_side {
    @include flex-center(flex-end, center);
    gap: 10px;

    i {
      color: $grey;
      font-size: 20px;
    }
  }
}

// Navbar Links
.navLink {
  @include flex-center(flex-start, center);
  color: #6F767E;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 20px;
  transition: color 0.3s;

  &:hover {
    color: $primary !important;
    cursor: pointer;
  }
}

// Active Navbar Link
.active_nav {
  color: $light !important;
  border-radius: 60px;
  background: $primary;
  transition: background 0.3s, color 0.3s;

  i {
    color: $light;
  }

  svg {
    color: $light;
    fill: $light;
    margin-right: 7px;
  }
}

.active_nav {
  &:hover {
    color: $light !important;
  }
}

// Navbar Menu
.navMenu {
  @include flex-center(space-between, center);
  z-index: 1;

  @media screen and (max-width: 1233px) {
    position: absolute;
    flex-direction: column;
    top: 70px;
    left: -100%;
    text-align: start;
    width: 100%;
    transition: 0.7s ease-in-out;
    background-color: $dark;
    padding: 10px;

    &.active {
      left: 0;
    }

    li {
      margin: 16px 0;
    }
  }
}

.navbar i {
  height: 20px;
  width: 20px;
  font-size: 20px;
  color: $dark;
  margin-right: 10px;
}