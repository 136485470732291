.table-container {
    .delete-button {
      background-color: #6b66da;
      /* Red background for delete button */
      border: none;
      color: white;
      padding: 5px 10px;
      cursor: pointer;
      font-size: 14px;
      border-radius: 4px;
      margin-left: 10px;
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
  
      th,
      td {
        padding: 10px;
        text-align: left;
        border: 1px solid #ddd;
        position: relative;
        /* Allows positioning of the delete button */
      }
  
      // th:last-child,
      // td:last-child {
      //   text-align: center;
      // }
  
      .delete-button-container {
        position: absolute;
        right: 10px;
        /* Align button to the right */
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
      }
    }
  }
  
  button:is(.button-height-varun){
    height: 40px !important;
    width: 90px !important;
    min-width: unset;
  }
  
  .table-input-field{
    height: 25px !important;
  }