@import '../../../assets//globalStyles/variables.scss';

// Date Picker 
.MuiInputBase-root,
.MuiOutlinedInput-root {
  background-color: #f4f4f4 !important;
  height: 51px;
  font-size: 14px !important;
  border-radius: 10px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  color: #1A1D1F;
}

.calender-input-component,
.error-container {
  display: contents;
}

.calender-input-component:focus-within,
.error-container:focus-within {
  background-color: $white;
  border: 1px solid $primary;
  outline: none;
  display: grid;
  overflow: hidden;
  border-radius: 10px;

  input {
    &:focus {
      border: none;
    }
  }

  div {
    background: $white !important;
  }
}

.error-container {

  .MuiInputBase-root {
    border: 1px solid $danger !important;
  }
}

.calender-input-component {
  input {
    font-weight: 500 !important;
    color: $dark !important;
  }

  button {
    color: $dark;
    width: 45px !important;
  }

  :first-child {
    width: 100%;
  }
}