.auth_screen {
    display: flex;
    height: 100vh;
    width: 100%;
}


.auth_image{
    flex: 50% 1;
      // background-image: url('../../../assets/branding/login2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    // box-shadow: inset linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.20) 20.43%, rgba(0, 0, 0, 0.00) 100%);
    // box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, 0.2), inset 0 0 0 0vmax rgba(0, 0, 0, 0.1);

}

.auth_content{
  background-color: #fff;
    flex: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh
}

.auth_child {
    flex: 1;
    max-width: 490px;
    min-width: 0;
    width: 100%;
    box-sizing: border-box;
  }

.logo_image {
width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
    .auth_image {
      display: none; /* Hide the auth-image on smaller screens */
    }
  
    .auth_content {
      flex: 100%; /* Take up the entire width of the screen */
    }
  }

