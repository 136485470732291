@import '../../../assets/globalStyles/variables.scss';

.search {
  flex: 1;
  max-width: 320px;
}

.search input:focus {
  outline: none;
  box-shadow: none;
}

.search .input-group,
.search .input-group-prepend .input-group-text i,
.search .form-control::placeholder,
input::placeholder {
  color: $grey !important;
}

::-webkit-input-placeholder {
  /* Safari, Chrome and Opera */
  color: $grey;
}

.search .input-group-prepend .input-group-text {
  height: 48px;
  border-radius: 24px 0 0 24px;
  border: none;
  background-color: $lightestGrey;
}

.search .form-control {
  border-radius: 24px;
  border: none;
  background-color: $lightestGrey;
  color: #6f767e;
}
