@import '../../../assets/globalStyles/variables.scss';

.footer{
        color: #6C757D;
        margin-top: auto;
        text-align: center;
        padding: 1.2rem 0;

        .blue{
            color: $primary;
            font-weight: 400 !important;
        }
}