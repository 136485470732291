.table-container {
  margin: 20px;
}

.filters {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filters label {
  display: flex;
  flex-direction: column;
}

.nested-table {
  border-collapse: collapse;
  width: 100%;
}

.nested-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

th,tr,td{
  text-align: left;
}

.graphs-container {
  margin-top: 30px;
}

.graphs-container .recharts-wrapper {
  margin-bottom: 20px;
}

.prompts-container {
  margin-top: 20px;
}

.prompt-item {
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.prompt-input {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 5px;
  min-height: 50px !important;
}

.remove-prompt-button {
  color: white;
  border: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}


.output-box {
  margin-top: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.add-prompt-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.add-prompt-button:hover {
  background-color: #2980b9;
}

.output-textarea {
  width: 100% !important;
  min-height: 350px !important;
  max-height: max-content;
}



// @media print {
//   .print-container {
//     padding: 20px;
//     margin: 20px;
//   }

//   .no-print {
//     display: none;
//   }

//   .no-page-break{
//     break-inside: avoid;
//     page-break-inside: avoid;
//     page-break-before: auto;
//     page-break-after: auto;
//   }

// }
@media print {
  .print-container {
    transform: scale(0.9); /* Adjust scale as needed */
    transform-origin: center top; /* Centers horizontally */
    // padding: 20px;
    margin: auto;
    width: 100%; /* Adjust to control the width and centering */
  }

  .no-print {
    display: none;
  }

  .no-page-break {
    break-inside: avoid;
    page-break-inside: avoid;
    page-break-before: auto;
    page-break-after: auto;
  }
}


.print-container {
  padding: 30px !important;
}


.currency-unit-selection {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.select-currency,
.select-unit {
  width: 200px;
}

.graph-heading{
  font-size: 22px;
  font-weight: 600;
  // margin-bottom: 20px;
  // margin-top: 20px;
}