$white: #ffffff;
$light: #efefef;
$lightestGrey : #f4f4f4;
$bgLight : #f5f5f5;
$bgDark : #111112;
$grey0 : #c0c4c9;
$grey: #6f767e;
$lightGrey: #ced5de;
$dark: #1a1d1f;
$green: #83bf6e;
$danger: #be0f00;
$yellow: #ffa700;
$primary:#145b64;

.fw-bold {
    font-weight: 500 !important;
}


.text-success {
    color: $green !important;
}

.text-dark {
    color: $dark !important;
}

.text-error{
    color:$danger !important;
}

.text-warning {
    color: $yellow !important ;
}