.table-container {
    margin: 20px;
}

.filters {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.filters label {
    display: flex;
    flex-direction: column;
}

.nested-table {
    border-collapse: collapse;
    width: 100%;
}

.nested-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.graphs-container {
    margin-top: 30px;
}

.graphs-container .recharts-wrapper {
    margin-bottom: 20px;
}

.prompts-container {
    margin-top: 20px;
}

.prompt-item {
    margin-bottom: 10px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.preview {
    .prompt-item {
        border: 0px !important;
        padding: 0
    }
}

.prompt-input {
    margin-bottom: 10px;
    padding: 8px;
    // border: 1px solid #ccc;
    border-radius: 5px;
    min-height: 50px !important;
}

.remove-prompt-button {
    // background-color: #e74c3c;
    color: white;
    border: none;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
}

.remove-prompt-button:hover {
    // background-color: #c0392b;
}

.output-box {
    margin-top: 10px;
    // padding: 10px;
    // border: 1px solid #ccc;
    border-radius: 5px;
    // background-color: #f9f9f9;
    margin-bottom: 20px;
}

.add-prompt-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.add-prompt-button:hover {
    background-color: #2980b9;
}

// .output-textarea {
//     width: 100% !important;
//     min-height: 350px !important;
//     max-height: max-content;
// }
.output-textarea {
    width: 100% !important;
    min-height: 350px !important;
    max-height: max-content;
    background-color: whitesmoke;
    padding: 10px;
}


.company-details h3 {
    font-size: 24px;
    margin-bottom: 20px;
}

.company-basic-info {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.company-basic-info td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;

}

.company-basic-info .label {
    font-weight: bold;
    background-color: #f4f4f4;
}

.address {
    margin: 10px 0;
}

.directors-table,
.shareholders-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.directors-table th,
.shareholders-table th,
.directors-table td,
.shareholders-table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
}

.directors-table th,
.shareholders-table th {
    background-color: #f4f4f4;
}

h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}


/////---------------


/* General styles for section headings */
.section-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
}

.section2-heading{
    font-size: 16px;
    // font-weight: bold;
    margin-bottom: 20px;
    margin-top: -10px;
}

/* Styles for the toggle switch container */
.preview-mode-text {
    font-weight: bold;
    padding-right: 20px;
    font-size: 20px;
}

/* Styles for tables */
.company-basic-info,
.directors-table,
.shareholders-table,
.balance-sheet-table,
.profit-loss-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.company-basic-info td,
.directors-table td,
.shareholders-table td,
.balance-sheet-table td,
.profit-loss-table td,
.company-basic-info th,
.directors-table th,
.shareholders-table th,
.balance-sheet-table th,
.profit-loss-table th {
    border: 1px solid #ddd;
    padding: 8px;
}


.balance-sheet-table th,
.profit-loss-table th {
background-color: #f2f2f2;
}

/* Styles for charts container */
.chart-container {
    margin-top: 20px;
}

/* Styles for select inputs */
.select-year,
.select-metric,
.select-chart-type {
    margin-top: 20px;
}

@media print {
    .no-page-break {
      break-inside: avoid;
      page-break-inside: avoid;
    }
  }