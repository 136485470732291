@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&family=Plus+Jakarta+Sans:wght@200;300;400;700;800&display=swap");
@import "typography.scss";
@import "variables.scss";
@import 'inputs';
@import 'custom';
@import 'forms';

// body{
//   background-color: $bgLight;
// }

:root {
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  font-synthesis: none;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: auto;
}

// Toast React Hot Toast Customization
.go2344853693:after {
  border-color: #1e273b !important ;
}

.go2534082608:after,
.go2534082608:before {
  background: rgb(190, 15, 0) !important;
}

.go2344853693:after {
  border-color: #83bf6e !important;
}

.go2344853693,
.go2534082608 {
  background-color: #fff !important;
}

hr{
  background-color: (0, 0, 0, 0.12);
  color : (0, 0, 0, 0.12)
}
.pointer {
  cursor: pointer;
}

.modal-dialog{
  width: 1000px !important;
}
.modal{
  display: flex !important;
  padding-left: 0px !important;
}

.modal-content{
  width: 100%;
}


.file-preview{
    display: flex !important;
    img{
      margin: 0px auto;
    }
}

::-webkit-scrollbar {
  background: transparent;
  width: 10px !important;
  scroll-behavior: smooth;
  border-radius: 12px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 20px;
  height: 30px;
}